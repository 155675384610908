/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export type RegionNameType = 'us-east-1' | 'eu-central-1' | 'ap-northeast-1' | 'cn-northwest-1';

export const defaultRegionNameForTest: RegionNameType = 'ap-northeast-1';

export const allRegions: Array<RegionNameType> = [
    'ap-northeast-1',
    'eu-central-1',
    'us-east-1',
    'cn-northwest-1',
];
