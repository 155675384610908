/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */

export type ThemeNameType =
    | 'siemens-horizon-light'
    | 'dark-gray'
    | 'calidum-dark'
    | 'calidum-light'
    | 'timberline-x'
    | 'medium-gray';

export const allThemes: Array<ThemeNameType> = [
    'siemens-horizon-light',
    'dark-gray',
    'calidum-dark',
    'calidum-light',
    'timberline-x',
    'medium-gray',
];
